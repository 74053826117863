<script setup lang="ts">
import {type Template, useGHG} from "../GHG";
import {computed, onMounted, ref} from "vue";
import Button from "../../components/Button.vue";
import Dropdown from "../../components/Dropdown.vue";
import Field from "../../components/Field.vue";
import Table from "../../components/Table.vue";
import Textbox from "../../components/Textbox.vue";
import Validation from "../../components/Validation.vue";
import {filterTemplates} from "../TemplateFilter";
import {useProgressBar} from "../../Wizard";

const ghg = useGHG();
const page = "fugitive";

const templates = ref<Template[]>();
onMounted(async () => templates.value = await ghg.value.templates);

const skip = () => ghg.value.next(page);
const next = () =>
{
    ghg.value.save(page, templates.value!, rows.value);
    skip();
};
useProgressBar(() => ghg.value.progress(page));

const refrigerants = computed(() => templates.value === undefined ? [] : filterTemplates(templates.value, {}, "refrigerant"));

interface Row
{
    name: string;
    refrigerant: string | null;
}
const rows = ref<Row[]>([{name: "", refrigerant: null}]);

const add = () => rows.value.push({name: "", refrigerant: null});
const remove = (index: number) => rows.value.splice(index, 1);
</script>
<template>
    <h1>Add fugitive emissions</h1>
    <p>Add all air conditioning, refrigeration systems, or fire extinguishers in your facilities.</p>
    <Validation>
        <template v-slot:default="{validate}">
            <Table collapse-last v-bind:columns="3" v-bind:loading="templates === undefined" v-bind:rows="templates === undefined ? [] : rows">
                <template v-slot:header:1>Fugitive emission source</template>
                <template v-slot:header:2>Refrigerant type</template>
                <template v-slot:column:1="{row}">
                    <Field class="flex-grow" rule="NonEmptyString" v-bind:value="row.name" v-slot="{id}">
                        <Textbox v-bind:id="id" v-model:value="row.name" placeholder="Airconditioner floor 1"/>
                    </Field>
                </template>
                <template v-slot:column:2="{row}">
                    <Field class="flex-grow" rule="NotNull" v-bind:value="row.refrigerant" v-slot="{id}">
                        <Dropdown v-bind:id="id" v-bind:items="refrigerants" v-model:selected="row.refrigerant"/>
                    </Field>
                </template>
                <template v-slot:column:3="{index}">
                    <div class="flex flex-gap-2">
                        <a aria-label="Delete" class="i-material-symbols:delete-outline-rounded link text-6" v-bind:class="rows.length === 1 ? 'invisible' : 'visible'" v-on:click="remove(index)"/>
                        <a aria-label="Add" class="i-fa6-solid:plus link text-6" v-bind:class="index === rows.length - 1 ? 'visible' : 'invisible'" v-on:click="add()"/>
                    </div>
                </template>
            </Table>
            <div class="flex flex-gap-6 flex-justify-center m-t-6">
                <Button role="primary" type="button" v-bind:disabled="templates === undefined" v-on:click="validate(true) && next()">Continue</Button>
                <a class="link" v-on:click="skip">Skip</a>
            </div>
        </template>
    </Validation>
</template>
