<script lang="ts" setup>
import ReportCard from "./ReportCard.vue";
import ReportCardItem from "./ReportCardItem.vue";
import {ReportMode} from "../ReportMode";
import Textarea from "../../components/Textarea.vue";
import {computed} from "vue";

interface Emits
{
    (event: "remove"): void;
    (event: "update:value", value: string): void;
}
interface Props
{
    mode?: ReportMode;
    value?: string;
}
const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(),
{
    mode: ReportMode.View,
    value: ""
});

const getText = () => props.value;
const setText = (value: string) => emit("update:value", value);
const text = computed({get: getText, set: setText});

const remove = () => emit("remove");
</script>
<template>
    <ReportCard v-bind:mode="mode" v-bind:value="value">
        <ReportCardItem v-bind:mode="mode" v-on:remove="remove">
            <div class="page-break-inside-avoid relative">
                <div class="absolute color-adjust-exact h-4 i-fa6-solid-quote-left left-0 m-r-2 text-body w-4"/>
                <Textarea class="p-x-5 p-y-3" placeholder="Quote" v-if="mode === ReportMode.Edit" v-model:value="text"/>
                <div class="overflow-x-hidden p-x-5 p-y-3" v-if="mode === ReportMode.Preview">That's one small step for man, one giant leap for mankind.</div>
                <div class="p-x-5 p-y-1 text-body overflow-wrap-anywhere white-space-pre-wrap" v-if="mode === ReportMode.View">{{value}}&ZeroWidthSpace;</div>
                <div class="absolute bottom-0 color-adjust-exact h-4 i-fa6-solid-quote-right m-l-2 right-0 text-body w-4"/>
            </div>
        </ReportCardItem>
    </ReportCard>
</template>