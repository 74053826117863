<script lang="ts" setup>
import type {GsiButtonConfiguration, IdConfiguration} from "google-one-tap";
import {ref, watchPostEffect} from "vue";
import Button from "../components/Button.vue";
import {GOOGLE_OAUTH_CLIENT_ID} from "../Environment";
import {fetch} from "../API";
import {useRouter} from "vue-router";

interface Emits
{
    (event: "fetch", status: boolean): void;
}
interface Props
{
    redirect?: string;
}

const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(), {redirect: "/"});

const router = useRouter();
const googleAccountsOpts: IdConfiguration =
{
    auto_select: false,
    callback: async ({credential}) =>
    {
        try
        {
            emit("fetch", true);
            await fetch("post", "/signin/google/", {}, {credential}, null);
            await router.push(props.redirect);
        }
        finally
        {
            emit("fetch", false);
        }
    },
    cancel_on_tap_outside: false,
    client_id: GOOGLE_OAUTH_CLIENT_ID,
    itp_support: true,
    log_level: import.meta.env.MODE === "development" ? "info" : undefined,
    use_fedcm_for_prompt: true,
    ux_mode: "popup"
};
google.accounts.id.initialize(googleAccountsOpts);
const button = ref<HTMLDivElement>();
const el = ref<HTMLDivElement>();
watchPostEffect(() =>
{
    const buttonOpts: GsiButtonConfiguration =
    {
        locale: "en",
        logo_alignment: "center",
        shape: "circle",
        size: "large",
        theme: "outline"
    };
    const safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    google.accounts.id.renderButton(safari ? button.value! : el.value!, buttonOpts);
    google.accounts.id.prompt();
});
const login = () => el.value!.querySelector<HTMLDivElement>("div[role=button]")!.click();
</script>
<template>
    <div class="grid-self-center" ref="button">
        <Button role="secondary" type="button" v-on:click="login">
            <span class="i-logos:google-icon text-5 m-r-2"/>
            <span>Sign in with Google</span>
        </Button>
    </div>
    <Teleport to="body">
        <div class="hidden" ref="el"/>
    </Teleport>
</template>