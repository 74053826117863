import type {Path, PathMethod, PathParams, PathQuery, PathRequestBody, PathRequestBodyStrict, PathResponseBody} from "../../api/main/API";
import {API_URL} from "./Environment";
import Authorization from "./Authorization";
import CSRF from "./CSRF";
import Captcha from "./Captcha";
import {showToast} from "./components/Toast";
import {url} from "../../api/main/API";

export type * from "../../api/main/API";

/**
 * Submits a HTTP request to the API server.
 *
 * @param method the HTTP method.
 * @param path the request path.
 * @param params the path parameters.
 * @param query the query parameters.
 * @param body the request body.
 * @returns the response from the API server.
 */
export async function fetch<P extends Path, M extends PathMethod<P>, B extends PathRequestBody<P, M>>(method: M, path: P, params: PathParams<P, M>, query: PathQuery<P, M>, body: PathRequestBodyStrict<P, M, B>, signal?: AbortSignal): Promise<PathResponseBody<P, M>>
{
    const {contentType, response} = await fetchApi(method, path, params, query, body, signal);
    if(/(^|; *)application\/json($|;)/.test(contentType ?? ""))
    {
        return await response.json();
    }
    else
    {
        return null as never;
    }
}

/**
 * Submits a HTTP request to the API server.
 *
 * @param method the HTTP method.
 * @param path the request path.
 * @param params the path parameters.
 * @param query the query parameters.
 * @param body the request body.
 * @returns the response from the API server.
 */
async function fetchApi<P extends Path, M extends PathMethod<P>, B extends PathRequestBody<P, M>>(method: M, path: P, params: PathParams<P, M>, query: PathQuery<P, M>, body: PathRequestBodyStrict<P, M, B>, signal?: AbortSignal)
{
    const action = `${String(method)}/${path.replaceAll(/[^A-Za-z0-9]/g, "_")}`;
    const captcha = await Captcha.captcha(action);
    const headers: [string, string | null][] =
    [
        ["Authorization", Authorization.get()],
        ["X-Captcha", captcha],
        ["X-CSRF-Token", CSRF.token()]
    ];
    const opts: RequestInit =
    {
        body: body as unknown === null ? undefined : JSON.stringify(body),
        credentials: "include",
        headers:
        {
            "Content-Type": "application/json",
            ...Object.fromEntries(headers.filter(([_, value]) => value !== null))
        },
        method: String(method).toUpperCase(),
        signal
    };
    const uri = url<P, M>(API_URL, path, params, query);
    const response = await window.fetch(uri, opts);
    if(response.ok)
    {
        const authorization = response.headers.get("authorization");
        if(authorization !== null)
        {
            Authorization.set(authorization);
        }
        const contentType = response.headers.get("content-type");
        return {contentType, response};
    }
    else if(response.status === 401)
    {
        document.location = "/signin/";
        return null as never;
    }
    else if(response.status === 402)
    {
        document.location = "/subscription/";
        return null as never;
    }
    else
    {
        const message = `${String(method).toUpperCase()} ${path} ${response.status} ${response.statusText}`;
        showToast(message);
        throw new Error(`${response.status} ${response.statusText}`);
    }
}

/**
 * Submits a HTTP request to the API server.
 *
 * @param method the HTTP method.
 * @param path the request path.
 * @param params the path parameters.
 * @param query the query parameters.
 * @param body the request body.
 * @returns the response stream from the API server.
 */
export async function* fetchStream<P extends Path, M extends PathMethod<P>, B extends PathRequestBody<P, M>>(method: M, path: P, params: PathParams<P, M>, query: PathQuery<P, M>, body: PathRequestBodyStrict<P, M, B>, signal?: AbortSignal): AsyncGenerator<PathResponseBody<P, M>, void, void>
{
    const {contentType, response} = await fetchApi(method, path, params, query, body, signal);
    if(/(^|; *)application\/json($|;)/.test(contentType ?? ""))
    {
        const reader = response.body!.pipeThrough(new TextDecoderStream()).getReader();
        const chunks = [];
        while(true)
        {
            const {done, value} = await reader.read();
            if(done)
            {
                break;
            }
            chunks.push(value);
            const lines = chunks.join("").split(/\n/);
            if(lines.length > 1)
            {
                for(let n = 0; n < lines.length - 1; n++)
                {
                    const line = lines[n];
                    if(line.length > 0)
                    {
                        yield JSON.parse(line);
                    }
                }
                chunks.splice(0, chunks.length, lines[lines.length - 1]);
            }
        }
    }
    else
    {
        return null as never;
    }
}