<script lang="ts" setup>
import {type Component, computed, shallowRef, useAttrs, watch} from "vue";
import PageLayout from "./PageLayout.vue";
import {useRoute} from "vue-router";

const route = useRoute();
const layout = shallowRef<Component | null>(null);
const attrs = useAttrs();

watch(computed(() => route.meta), () => layout.value = route.matched.reduce<Component | null>((a, {meta: {layout}}) => layout ?? a, PageLayout), {immediate: true});
</script>
<template>
    <component v-if="layout" v-bind="attrs" v-bind:is="layout"/>
</template>