<script lang="ts" setup>
import {type ConnectionStatus, fetch} from "../API";
import {onMounted, onUnmounted, ref} from "vue";
import Button from "../components/Button.vue";
import Checkbox from "../components/Checkbox.vue";
import type {ComponentExposed} from "vue-component-type-helpers";
import Table from "../components/Table.vue";
import Textbox from "../components/Textbox.vue";
import Validation from "../components/Validation.vue";
import {awaitable} from "../components/Awaitable";
import {useRules} from "../components/Validator";

interface Emits
{
    (event: "save"): void;
    (event: "update:selected", selected: string[]): void;
}
interface Props
{
    saving: boolean;
    selected: string[] | null;
}
const props = withDefaults(defineProps<Props>(), {});
const emits = defineEmits<Emits>();

interface Connection
{
    email: string;
    id: string;
    status: ConnectionStatus;
}

const connections = ref<Connection[] | null>(null);
onMounted(async () => connections.value = await fetch("get", "/connection/", {}, {}, null));

const email = ref("");
const textbox = ref<ComponentExposed<typeof Textbox>>();
const validation = ref<ComponentExposed<typeof Validation>>();

const adding = ref(false);
const add = awaitable(adding, async () =>
{
    const {id, status} = await fetch("post", "/connection/", {}, {}, {email: email.value});
    connections.value!.push({email: email.value, id, status});
    email.value = "";
});
const unique = () => connections.value?.every(({email: existing}) => existing !== email.value);
const submit = async () =>
{
    if(validation.value!.validate(true) && unique())
    {
        await add();
    }
    textbox.value!.focus();
};

const recheck = async () =>
{
    if(connections.value !== null)
    {
        const recheck = connections.value.filter(({status}) => status === "Sending");
        const promises = recheck.map(({id: connectionId}) => fetch("get", "/connection/{connectionId}/", {connectionId}, {fields: ["status"]}, null).then(({status}) => ({connectionId, status})));
        const statuses = await Promise.all(promises);
        connections.value = connections.value.map(({id, status, ...rest}) => ({id, status: statuses.reduce((a, {connectionId, status}) => connectionId === id ? status! : a, status), ...rest}));
    }
};
onMounted(() =>
{
    const interval = setInterval(recheck, 5000);
    onUnmounted(() => clearInterval(interval));
});

const never = (_: never) => _;

const save = () => emits("save");
const select = (checked: boolean, id: string) =>
{
    const {selected} = props;
    const value = checked ? [...selected!, id] : selected!.filter((selected) => selected !== id);
    emits("update:selected", value);
};
</script>
<template>
    <div class="flex flex-col flex-gap-3 items-center">
        <h2>Select connection to share indicators with</h2>
        <Table collapse-first collapse-last v-bind:columns="2" v-bind:rows="selected === null ? null : connections">
            <template v-slot:header:1>Email</template>
            <template v-slot:header:2>Status</template>
            <template v-slot:column:1="{row: {email, id}}">
                <Checkbox v-bind:value="selected!.includes(id)" v-on:update:value="(checked) => select(checked, id)">
                    <span class="break-all">{{email}}</span>
                </Checkbox>
            </template>
            <template v-slot:column:2="{row: {email, status}}">
                <template v-if="status === 'Linked'">Account Linked</template>
                <template v-else-if="status === 'Invalid'">
                    <div class="grid grid-gap-1 grid-rows-[auto_auto]">
                        <div class="color-pink">Error</div>
                        <div class="text-small">
                            <span>{{email.substring(email.indexOf('@'))}} is not an email domain</span>
                        </div>
                    </div>
                </template>
                <template v-else-if="status === 'Sending'">
                    <a class="m-r-1 color-gray i-svg-spinners:180-ring-with-bg"/>
                    <span>Sending</span>
                </template>
                <template v-else-if="status === 'Sent'">Sent</template>
                <template v-else>{{never(status)}}</template>
            </template>
            <template v-slot:placeholder>
                <Validation v-bind:validator="useRules('Email')" v-bind:value="email" v-slot="{validate}">
                    <form class="flex flex-grow flex-items-center" v-on:submit.prevent="submit()">
                        <Textbox placeholder="alice@example.org" ref="textbox" v-bind:disabled="adding" v-model:value="email"/>
                        <a class="color-green i-svg-spinners:180-ring-with-bg text-6" v-if="adding"/>
                        <a class="i-material-symbols:add-circle-outline link text-6" v-bind:aria-disabled="unique() === false || validate() === false" v-on:click="submit()" v-else/>
                    </form>
                </Validation>
            </template>
        </Table>
        <Button role="primary" type="button" v-bind:disabled="connections === null || selected === null" v-bind:loading="saving" v-on:click="save">Save</Button>
    </div>
</template>