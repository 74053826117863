<script setup lang="ts">
import Button from "../../components/Button.vue";
import IconList from "../../components/IconList.vue";
import {useGHG} from "../GHG";
import {useProgressBar} from "../../Wizard";

const ghg = useGHG();
const page = "checklist";

const next = () => ghg.value.next(page);
useProgressBar(() => ghg.value.progress(page));
</script>
<template>
    <h1>Checklist before you start</h1>
    <p>To report GHG emissions, please prepare the following information:</p>
    <IconList icon="i-ic:baseline-check">
        <template v-slot:1>
            <span class="font-bold">Stationary sources: </span>
            <span>Find fuel types of all boilers, furnaces, ovens, or emergency electricity generator used in your facilities</span>
        </template>
        <template v-slot:2>
            <span class="font-bold">Mobile sources: </span>
            <span>Find fuel types of all vehicles owned by your organization for business purpose</span>
        </template>
        <template v-slot:3>
            <span class="font-bold">Fugitive emissions: </span>
            <span>Find refrigerant types of all air conditioning, refrigeration systems, or fire extinguishers in your facilities</span>
        </template>
        <template v-slot:4>
            <span class="font-bold">GHG removal: </span>
            <span>Identify facilities with plants that are at least 5 meters in height</span>
        </template>
        <template v-slot:5>
            <span class="font-bold">Indirect emissions: </span>
            <span>Identify electric or gas companies your facilities use to purchase electricity or gas</span>
        </template>
    </IconList>
    <div class="flex flex-justify-center m-t-6">
        <Button role="primary" type="button" v-on:click="next()">Continue</Button>
    </div>
</template>
