<script lang="ts" setup>
import {ref} from "vue";

interface Emits
{
    (event: "update:value", value: string): void;
}
interface Props
{
    disabled?: boolean,
    placeholder?: string,
    readonly?: boolean,
    value?: string
}
const emit = defineEmits<Emits>();
withDefaults(defineProps<Props>(),
{
    disabled: false,
    placeholder: "",
    readonly: false,
    value: ""
});
const el = ref<HTMLInputElement>();
const focus = () => el.value!.focus();
const update = () => emit("update:value", el.value!.value);
defineExpose({el, focus});
</script>
<template>
    <input class="b-none b-b-1 b-b-color-inherit b-b-solid bg-white block box-content disabled-color-middlegray disabled-cursor-default font-sans color-inherit group-[.invalid:not(:hover)]-parent-[.invalid]-color-pink h-10 outline-none p-0 placeholder-color-middlegray read-only-cursor-default enabled-read-only-border-white text-body w-100%" ref="el" type="text" v-bind:disabled="disabled" v-bind:placeholder="placeholder" v-bind:readonly="readonly" v-bind:value="value" v-on:input="update()"/>
</template>