<script lang="ts" setup>
import {onBeforeUnmount, onMounted, ref, useSlots, watch} from "vue";
import BasicCard from "./BasicCard.vue";

interface Props
{
    disabled?: boolean;
    loading?: boolean;
    menu?: {action: () => void; text: string}[];
}
const props = withDefaults(defineProps<Props>(), {disabled: false, menu: () => []});

const popup = ref(false);
const show = () =>
{
    if(props.disabled === false)
    {
        popup.value = !popup.value;
    }
};

const dropdown = ref<HTMLDivElement | null>(null);
watch(popup, () =>
{
    if(popup.value && dropdown.value !== null)
    {
        dropdown.value.style.right = "";
        const {right} = dropdown.value.getBoundingClientRect();
        dropdown.value.style.right = right < window.innerWidth ? "" : "0";
    }
});

const id = `id_${Math.random().toString(36).substring(2)}`;
const dismiss = (event: MouseEvent) =>
{
    if(event.target)
    {
        const target = event.target as HTMLElement;
        if(target.closest(`#${id}`) === null)
        {
            popup.value = false;
        }
        else
        {
            if(props.disabled === true || popup.value === true)
            {
                popup.value = false;
            }
            else
            {
                popup.value = true;
            }
        }
    }
};
onMounted(() => window.addEventListener("click", dismiss));
onBeforeUnmount(() => window.removeEventListener("click", dismiss));

const slots = useSlots();
</script>
<template>
    <BasicCard class="" v-bind:loading="loading">
        <template v-slot:header v-if="slots.header">
            <slot name="header"/>
        </template>
        <template v-slot:default>
            <div class="text-center">
                <slot/>
            </div>
            <div class="absolute bottom-2 right-2" role="menu" v-bind:id="id" v-if="menu.length > 0">
                <div class="block b-rd-50% flex flex-items-center flex-justify-center hover:color-green h-8 w-8" v-bind:class="[popup ? 'color-green' : 'color-black']" v-on:click.stop="show">
                    <div class="i-fa6-solid:ellipsis text-body"/>
                </div>
                <div class="absolute b-rd-1 b-solid b-lightgray b-1 bg-white box-border drop-shadow min-w-48 transform-origin-t transition transition-property-transform z-1" ref="dropdown" v-bind:class="popup ? 'transform-scale-y-100' : 'transform-scale-y-0'">
                    <div class="b-rd-1 bg-white hover:color-green transition-background-color p-3 text-nowrap" role="menuitem" v-bind:key="index" v-for="({action, text}, index) of menu" v-on:click.stop="popup = false, action()">{{text}}</div>
                </div>
            </div>
        </template>
    </BasicCard>
</template>