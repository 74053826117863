<script lang="ts" setup>
import type {IndicatorFormula, Measurements} from "../API";
import {computed, onMounted, ref} from "vue";
import Chart from "./Chart.vue";
import Loader from "../components/Loader.vue";
import {fetch} from "../API";
import {useBreadcrumb} from "../Breadcrumb";

interface Props
{
    projectId: string;
}
const props = withDefaults(defineProps<Props>(), {});

interface Indicator
{
    currency?: string;
    formula?: IndicatorFormula;
    name: string;
    options?: string[];
    parameter?: string;
    project: string;
    unit?: string;
    values: Measurements
}
const indicators = ref<Indicator[] | null>(null);
onMounted(async () =>
{
    const {projectId} = props;
    indicators.value = await fetch("get", "/project/{projectId}/analyze/", {projectId}, {}, null);
});

const noIndicators = computed(() => indicators.value === null ? false : indicators.value.length === 0);

useBreadcrumb();
</script>
<template>
    <h1>Analyze impact</h1>
    <Loader v-bind:loading="indicators === null">
        <template v-if="noIndicators">
            <p>You have not added any indicator to measure and analyze impact yet. Go to <router-link class="link" v-bind:to="`/project/${projectId}/indicators/`">Add indicator</router-link> to define what impact to analyze.</p>
        </template>
        <div v-bind:key="index" v-for="({currency, name, options, parameter, project, unit, values}, index) of indicators">
            <h2>{{name}}</h2>
            <h3>{{project}}</h3>
            <h4 v-if="parameter">{{parameter}}</h4>
            <Chart v-bind:indicator="{currency, name, options, unit}" v-bind:measurements="values"/>
        </div>
    </Loader>
</template>
