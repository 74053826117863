import type {Path, PathMethod, PathParams, PathQuery, PathRequestBody, PathRequestBodyStrict, PathResponseBody} from "../API";
import {nextTick, ref} from "vue";
import {awaitableWithAbort} from "./Awaitable";
import {debounceWithCancel} from "./Debounce";
import {fetch as fetchAPI} from "../API";

/**
 * Creates an autosave API.
 *
 * @param method the HTTP method.
 * @param path the request path.
 * @param fn the function used to create the fetch parameters.
 * @returns the API with an autosave function, a manual save function and a saving state.
 */
export function useAutosave<P extends Path, M extends PathMethod<P>>(method: M, path: P, fn: (fn: <T extends PathRequestBody<P, M>>(params: PathParams<P, M>, query: PathQuery<P, M>, body: PathRequestBodyStrict<P, M, T>) => Promise<PathResponseBody<P, M> | undefined>) => Promise<void>)
{
    const fetch = async <T extends PathRequestBody<P, M>>(abort: AbortController, params: PathParams<P, M>, query: PathQuery<P, M>, body: PathRequestBodyStrict<P, M, T>) => await fetchAPI(method, path, params, query, body, abort.signal);
    const saving = ref(false);
    const {abort, abortable} = awaitableWithAbort(saving, fetch);
    const {cancel, debounce} = debounceWithCancel(4000, <T extends PathRequestBody<P, M>>(params: PathParams<P, M>, query: PathQuery<P, M>, body: PathRequestBodyStrict<P, M, T>) => abortable(params, query, body));
    const undelayed = () => fn(abortable);
    const save = async () =>
    {
        if(saving.value === false)
        {
            if(cancel())
            {
                await undelayed();
            }
        }
    };
    const autosave = () => nextTick(() => fn(async <T extends PathRequestBody<P, M>>(params: PathParams<P, M>, query: PathQuery<P, M>, body: PathRequestBodyStrict<P, M, T>) => debounce(params, query, body)));
    return {abort, autosave, cancel, save, saving};
}