<script lang="ts" setup>
import {computed, ref} from "vue";
import {onBeforeRouteLeave, useRouter} from "vue-router";
import Accordion from "../components/Accordion.vue";
import Button from "../components/Button.vue";
import ToC from "../components/TOC.vue";
import {awaitable} from "../components/Awaitable";
import {encode} from "gpt-tokenizer";
import {extractText} from "../components/PDF";
import {fetchStream} from "../API";
import {useAutosave} from "../components/Autosave";
import {useBooleanPreference} from "../components/Preference";
import {useBreadcrumb} from "../Breadcrumb";
import {useSWR} from "../SWR";

interface Props
{
    projectId: string;
}
const props = withDefaults(defineProps<Props>(), {});

const {data: project, mutate} = useSWR("/project/{projectId}/", {projectId: props.projectId}, {fields: ["name", "toc"]}, null);

const {abort, autosave, save, saving} = useAutosave("patch", "/project/{projectId}/", async (fetch) =>
{
    const {projectId} = props;
    await fetch({projectId}, {}, {toc: toc.value!});
    await mutate();
});
const router = useRouter();
const submit = async () =>
{
    if(saving.value === false)
    {
        await save();
    }
    await router.push(`/project/${props.projectId}/`);
};

const getToc = (): [string, string, string, string, string] =>
{
    if(project.value === null || project.value === undefined || project.value.toc === undefined)
    {
        return ["", "", "", "", ""];
    }
    else
    {
        const toc = project.value.toc;
        return [toc[0], toc[1], toc[2], toc[3], toc[4]];
    }
};
const setToc = (value: [string, string, string, string, string]) =>
{
    project.value!.toc = value;
    autosave();
};
const toc = computed({get: getToc, set: setToc});

function *split(maximum: number, text: string)
{
    const tokens = encode(text);
    let n: number;
    for(n = 0; n < tokens.length; n += maximum)
    {
        const chunk = tokens.slice(n, n + maximum);
        yield chunk;
    }
    if(n < tokens.length)
    {
        yield tokens.slice(n);
    }
}

const input = ref<HTMLInputElement>();
const select = () => input.value!.click();

const filename = ref<string>();
const status = ref<"finished" | "idle" | "no-content" | "not-pdf">("idle");
const working = ref(false);
const upload = awaitable(working, async () =>
{
    const {files} = input.value!;
    if(files !== null && files.length === 1)
    {
        const [file] = files;
        const {name, type} = file;
        filename.value = name;
        if(type === "application/pdf")
        {
            const reader = new FileReader();
            const buffer = await new Promise<ArrayBuffer>((resolve, reject) =>
            {
                reader.addEventListener("load", () => resolve(reader.result as ArrayBuffer));
                reader.addEventListener("error", () => reject(reader.error));
                reader.readAsArrayBuffer(file);
            });
            const pages = await extractText(buffer);
            const text = pages.join("\n");
            if(text.length === 0)
            {
                status.value = "no-content";
            }
            else
            {
                abort();
                const tocs: [string, string, string, string, string] = ["", "", "", "", ""];
                for(const chunk of split(14000, text))
                {
                    const {projectId} = props;
                    const response = fetchStream("post", "/project/{projectId}/toc/", {projectId}, {}, chunk);
                    for await (const toc of response)
                    {
                        const {section, text} = toc;
                        const value = tocs[section];
                        tocs[section] = `${value === "" ? "" : `${value}\n`}${text}`;
                        project.value!.toc = [...tocs];
                    }
                }
                status.value = "finished";
                autosave();
            }
        }
        else
        {
            status.value = "not-pdf";
        }
    }
});

const expanded = useBooleanPreference("pdf", true);
const disabled = computed(() => project.value === undefined || project.value === null || working.value === true);

onBeforeRouteLeave(async () => await save());
useBreadcrumb({projectId: () => project.value?.name});
</script>
<template>
    <h1>Specify Theory of Change</h1>
    <p>Theory of Change is a method social impact makers widely use to outline how a project’s desired impacts are expected to happen.</p>
    <Accordion v-model:expanded="expanded">
        <template v-slot:header>Extract from PDF</template>
        <template v-slot:default>
            <div class="flex flex-gap-3 flex-justify-between">
                <div v-if="status === 'idle'">Upload an existing grant application, grant report or impact report in <b>PDF format</b>. Once selected, we’ll automatically process the file and extract the <b>first draft of Theory of Change</b>.</div>
                <div class="text-pink" v-else-if="status === 'no-content'">The file "{{filename}}" you uploaded does not contain any text.</div>
                <div class="text-pink" v-else-if="status === 'not-pdf'">The file "{{filename}}" you uploaded is not a PDF. Please upload a PDF to extract the draft of Theory of Change.</div>
                <div v-else-if="status === 'finished'">Based on "{{filename}}", the following draft of Theory of Change has been extracted. Upload another document for a different draft.</div>
                <div>
                    <Button fitted role="secondary" type="button" v-bind:loading="working" v-on:click="select">Upload</Button>
                </div>
                <input accept=".pdf" class="hidden" ref="input" type="file" v-on:change="upload" v-on:click="(e) => e.target!.value = ''"/>
            </div>
        </template>
    </Accordion>
    <form class="flex flex-col flex-gap-5" v-on:submit.prevent="submit()">
        <ToC v-bind:disabled="disabled" v-model:toc="toc"/>
        <Button class="flex-self-center" role="primary" type="submit" v-bind:disabled="disabled" v-bind:loading="saving">Save</Button>
    </form>
</template>