import {AccessorNode, AssignmentNode, ConstantNode, FunctionAssignmentNode, type MathNode, Node, ObjectNode, SymbolNode, Unit, createUnit, evaluate, format, parse, simplify, unit} from "mathjs";

export function extractNumber(expressions: string[] | MathNode[], unit: string)
{
    return expressions.flatMap((s) => s instanceof Node ? [s] : [parse(s)]).flatMap((node) => node instanceof FunctionAssignmentNode ? [node].map(({expr}) => expr) : [node]).map((node) => `${format(node)} / ${unit}`).map((node) => simplify(node)).map((node) => format(node)).map((node) => evaluate(node)).map((v) => Number(v));
}

export function extractExpressionUnit(expressions: string[] | MathNode[])
{
    return expressions.flatMap((s) => s instanceof Node ? [s] : [parse(s)]).flatMap((node) => node instanceof FunctionAssignmentNode ? [node].flatMap(({expr, params}) => expr instanceof Node ? [{expr, params}] : []).map(({expr, params}) => expr.transform((n) => n instanceof SymbolNode && params.includes(n.name) ? new ConstantNode(1) : n)) : [node]).map((node) => node.transform((node) => node instanceof ConstantNode ? new ConstantNode(1) : node)).map((node) => simplify(node)).map((node) => format(node));
}

export function extractExpressionUnits(expressions: string[] | MathNode[])
{
    const units = new Set<string>();
    const nodes = expressions.flatMap((s) => s instanceof Node ? [s] : [parse(s)]);
    nodes.flatMap((node) => node instanceof FunctionAssignmentNode ? [node] : []).flatMap(({expr, params}) => expr instanceof Node ? [{expr, params}] : []).map(({expr, params}) => extractSymbols(expr).filter((name) => params.includes(name) === false)).reduce((set, units) => units.reduce((set, unit) => set.add(unit), set), units);
    nodes.flatMap((node) => node instanceof AssignmentNode ? [node] : []).map(({value}) => extractSymbols(value)).reduce((set, units) => units.reduce((set, unit) => set.add(unit), set), units);
    nodes.flatMap((node) => node instanceof AssignmentNode || node instanceof FunctionAssignmentNode ? [] : [node]).map((node) => extractSymbols(node)).reduce((set, units) => units.reduce((set, unit) => set.add(unit), set), units);
    return units;
}

export function extractFunctionIndices(expressions: string[] | MathNode[])
{
    return expressions.flatMap((s) => s instanceof Node ? [s] : [parse(s)]).flatMap((node) => node instanceof FunctionAssignmentNode ? [node] : []).map(({expr}) => expr as MathNode).flatMap((node) => node.filter((node) => node instanceof AccessorNode)).flatMap((node) => node instanceof AccessorNode ? [node] : []).flatMap(({index: {dimensions}, object}) => ({dimensions, object: object as MathNode})).map(({dimensions, object}) => ({indices: new Set(dimensions.flatMap((node) => node.filter((node) => node instanceof SymbolNode)).flatMap((node) => node instanceof SymbolNode ? [node] : []).map(({name}) => name)), keys: object.filter((node) => node instanceof ObjectNode).flatMap((node) => node instanceof ObjectNode ? [node] : []).flatMap(({properties}: {properties: object}) => Object.keys(properties))}));
}

export function extractFunctionParameters(expressions: string[] | MathNode[])
{
    return expressions.flatMap((s) => s instanceof Node ? [s] : [parse(s)]).flatMap((node) => node instanceof FunctionAssignmentNode ? [node] : []).reduce((set, {params}) => params.reduce((set, param) => set.add(param), set), new Set<string>());
}

function extractSymbols(node: MathNode)
{
    return node.filter((node) => node).flatMap((node) => node instanceof SymbolNode ? [node] : []).map(({name}) => name);
}

export function introduceStandardizedUnits()
{
    createUnit("Btu", {definition: unit(1055.06, "J")});
    createUnit("d", {definition: unit(1, "day")});
    createUnit("Ha", {definition: unit(10000, "m^2")});
    createUnit("Towngas_unit", {definition: unit(48, "MJ")});
    createUnit("y", {definition: unit(1, "year")});
}

export function introduceUnspecifiedUnits(units: Iterable<string>)
{
    Unit.isValidAlpha = (c: string) => /^[A-Za-z_]$/.test(c);
    for(const name of units)
    {
        try
        {
            unit(1, name);
        }
        catch(e)
        {
            try
            {
                createUnit(name);
            }
            catch(e)
            {
                continue;
            }
        }
    }
}

export function runExpression(expression: string, variables: Record<string, {unit?: string; value: number | string}>)
{
    let node;
    try
    {
        node = parse(expression);
    }
    catch(e)
    {
        return undefined;
    }
    if(node instanceof FunctionAssignmentNode)
    {
        Unit.isValidAlpha = (c: string) => /^[A-Za-z_]$/.test(c);
        const {expr, name, params} = node;
        const units = extractExpressionUnits([node]);
        introduceStandardizedUnits();
        introduceUnspecifiedUnits(units);
        const scope = Object.fromEntries(params.map((param) => [param, Object.entries(variables).filter(([name]) => name === param).map(([_, {unit: name, value}]) => name === undefined || name === "" || typeof value === "string" ? value : unit(value, name)).reduce((_, b) => b, 1)]));
        const result = evaluate(expr.toString(), scope);
        return `${name}(${Object.keys(scope).join()}) = ${format(result, {notation: "fixed"})}`;
    }
}