<script lang="ts">
import type {Navigation} from "./Navigation";

export const navigation = () =>
{
    const navigation: Navigation[] =
    [
        {
            href: "/project/",
            icon: "i-material-symbols:folder-outline",
            text: "All projects"
        },
        {
            href: "/template/",
            icon: "i-material-symbols:precision-manufacturing",
            scopes: ["administrator"],
            text: "Indicator templates"
        }
    ];
    return navigation;
};

export default {};
</script>
