<script generic="T" lang="ts" setup>
type Column<T> = {[_ in `column:${number}`]: (_: {index: number; row: T}) => any};
type Header = {[_ in `header:${number}`]: (_: {}) => any};
type Placeholder = {"placeholder": (_: {}) => any};

interface Props
{
    collapseFirst?: boolean;
    collapseLast?: boolean;
    columns: number;
    loading?: boolean;
    rows: T[] | undefined | null;
}
withDefaults(defineProps<Props>(),
{
    collapseFirst: false,
    collapseLast: false,
    loading: false
});
const slots = defineSlots<Column<T> & Header & Placeholder>();
</script>
<template>
    <div class="grid grid-auto-rows-[1fr] grid-rows-[auto] transition-grid-template-rows w-100%" v-bind:style="{gridTemplateColumns: `repeat(${Math.max(1, columns - 1)}, minmax(min-content, 1fr)) min-content`, gridTemplateRows: `min-content repeat(${rows?.length ?? 0}, 1fr) min-content`}">
        <div class="color-gray font-bold hidden grid-col-[1/-1] grid-cols-subgrid sm-grid">
            <div class="flex flex-items-center p-x-6 p-y-3" v-bind:key="column" v-for="column of columns">
                <slot v-bind:name="`header:${column}`"/>
            </div>
        </div>
        <div class="b-1px b-t-solid b-lightgray grid grid-col-[1/-1] grid-cols-subgrid sm-grid-rows-subgrid" v-bind:key="index" v-for="(row, index) of rows">
            <template v-for="column of columns">
                <div class="flex flex-items-center p-x-6 p-y-3 sm-grid-col-auto sm-grid-row-auto" v-bind:class="[column === 1 && collapseFirst ? `grid-area-[1/1/1000/1]` : column === columns && collapseLast ? `grid-area-[1/-1/1000/-1]` : collapseFirst && collapseLast ? 'grid-area-[auto/2/auto/-2]' : collapseFirst ? 'grid-area-[auto/2/auto/-1]' : collapseLast ? 'grid-area-[auto/1/auto/-2]' : 'grid-area-[auto/1/auto/-1]']">
                    <slot v-bind:index="index" v-bind:name="`column:${column}`" v-bind:row="row"/>
                </div>
            </template>
        </div>
        <div class="b-1px b-lightgray b-t-solid flex flex-items-center flex-justify-center grid-col-[1/-1] p-x-6 p-y-3" v-if="loading || rows === null || rows === undefined">
            <span class="color-green i-svg-spinners:180-ring-with-bg text-5"/>
        </div>
        <div class="b-1px b-lightgray b-t-solid b-t-solid flex flex-items-center grid-col-[1/-1] p-x-6 p-y-3" v-else-if="slots['placeholder']">
            <slot name="placeholder"/>
        </div>
    </div>
</template>